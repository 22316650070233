// @flow
import * as React from 'react';

import { isEmpty } from 'lodash';
import { useReactiveVar } from '@apollo/client';
import cs from './styles.pcss';
import Button from '../../../basic-components/button';
import TwoColumnsLayout from '../../../basic-components/two-columns-layout';
import { gpsrConfigsVar } from '../../../utils/apollo-cache';
import { getOptions } from '../../../store/gpsr';
import t from '../locale';
import Link from '../../../../landings/link';
import Icon from "../../../basic-components/icon";

export function ManufacturerRow({
  manufacturer,
  onDelete,
  onSelected,
  inProduct,
  disabled,
}: {
  manufacturer: any,
  onDelete: () => void,
  onSelected: () => void,
  inProduct?: boolean,
  disabled?: boolean,
}): React.Node {
  const countries = getOptions(useReactiveVar(gpsrConfigsVar), 'country');
  return (
    <>
      {!isEmpty(manufacturer) && (
        <TwoColumnsLayout
          left={
            <div className={inProduct ? cs.inProduct : ''}>
              <div>
                <span>{manufacturer.companyName}</span>
              </div>
              <div>
                <span>
                  {countries
                    .filter((item) =>
                      manufacturer.country?.includes(item.value)
                    )
                    ?.map((item) => (
                      <div>{item.label}</div>
                    ))}
                </span>
              </div>
              <div>
                <span>{manufacturer.street1}</span>
              </div>
              <div>
                <span>{manufacturer.street2}</span>
              </div>
              <div>
                <span>{manufacturer.cityName}</span>
              </div>
              <div>
                <span>{manufacturer.postalCode}</span>
              </div>
              <div>
                <span>{manufacturer.stateOrProvince}</span>
              </div>
              <div>
                <span>{manufacturer.phone}</span>
              </div>
              <div>
                <span>{manufacturer.email}</span>
              </div>
              <div>
                <span>{manufacturer.contactUrl}</span>
              </div>
            </div>
          }
          right={
            <>
              {!isEmpty(manufacturer) && (
                <div className={cs.trash}>
                  <Button
                    className={cs.button}
                    size="medium"
                    onClick={onSelected}
                    disabled={disabled}
                  >
                    {' '}
                    {t('buttons.edit')}{' '}
                  </Button>
                  <Button size="medium" onClick={onDelete} disabled={disabled}>
                    {' '}
                    {t('buttons.delete')}{' '}
                  </Button>
                </div>
              )}
            </>
          }
        />
      )}
    </>
  );
}

export function ResponsiblePersonsRow({
  responsiblePersons,
  onSelected,
  onDelete,
  inProduct,
  disabled,
}: {
  responsiblePersons: Array<any>,
  onSelected: (index: number) => void,
  onDelete: (index: number) => void,
  inProduct?: ?boolean,
  disabled?: boolean,
}): React.Node {
  const countries = getOptions(useReactiveVar(gpsrConfigsVar), 'country');
  const responsiblePersonsRenderRow = (responsiblePerson, index) => (
    <TwoColumnsLayout
      key={index}
      left={
        <div className={inProduct ? cs.inProduct : ''}>
          <div>
            <span>{responsiblePerson.companyName}</span>
          </div>
          <div>
            <span>
              {countries
                .filter((item) =>
                  responsiblePerson.country?.includes(item.value)
                )
                ?.map((item) => (
                  <div>{item.label}</div>
                ))}
            </span>
          </div>
          <div>
            <span>{responsiblePerson.street1}</span>
          </div>
          <div>
            <span>{responsiblePerson.street2}</span>
          </div>
          <div>
            <span>{responsiblePerson.cityName}</span>
          </div>
          <div>
            <span>{responsiblePerson.postalCode}</span>
          </div>
          <div>
            <span>{responsiblePerson.stateOrProvince}</span>
          </div>
          <div>
            <span>{responsiblePerson.phone}</span>
          </div>
          <div>
            <span>{responsiblePerson.email}</span>
          </div>
          <div>
            <span>{responsiblePerson.contactUrl}</span>
          </div>
        </div>
      }
      right={
        <div className={inProduct ? cs.inProduct : ''}>
          <div>
            <span>
              <div className={cs.trash}>
                <Button
                  className={cs.button}
                  size="medium"
                  onClick={() => onSelected(index)}
                  disabled={disabled}
                >
                  {' '}
                  {t('buttons.edit')}{' '}
                </Button>
                <Button
                  size="medium"
                  onClick={() => onDelete(index)}
                  disabled={disabled}
                >
                  {' '}
                  {t('buttons.delete')}{' '}
                </Button>
              </div>
            </span>
          </div>
        </div>
      }
    />
  );

  return (
    <>
      {!isEmpty(responsiblePersons) &&
        responsiblePersons.map(responsiblePersonsRenderRow)}
    </>
  );
}

export function DocumentRow({
  documents,
  onSelected,
  onDelete,
  inProduct,
  disabled,
}: {
  documents: Array<any>,
  onSelected: (index: number) => void,
  onDelete: (index: number) => void,
  inProduct?: ?boolean,
  disabled?: boolean,
}): React.Node {
  const allLanguages = getOptions(useReactiveVar(gpsrConfigsVar), 'languages');
  const documentTypes = getOptions(
    useReactiveVar(gpsrConfigsVar),
    'documentTypes'
  );
  const renderRow = (document, index) => (
    <TwoColumnsLayout
      key={index}
      left={
        <div className={inProduct ? cs.inProduct : ''}>
          <div>
            <span>
              {documentTypes
                .filter((item) => item.value === document.documentType)
                .map((item) => item.label)}
            </span>
          </div>
          <div>
            <span>
              {allLanguages
                .filter((item) => document.languages?.includes(item.value))
                .map((item) => item.label)
                .join(',')}
            </span>
          </div>
          <div
            className={
              document.documentFile && !document.documentFile?.endsWith('pdf')
                ? ''
                : cs.hide
            }
          >
            <span>
              {document.documentFileName ? (
                <div>
                  <Link
                    href={document.documentFile ? document.documentFile : ''}
                    target="_blank"
                    area=""
                  >
                    <Icon name="attach" size={16} />
                    {document.documentFileName}
                  </Link>
                </div>
              ) : (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <></>
              )}
              {/* eslint-disable-next-line jsx-a11y/alt-text */}
              <img
                src={document.documentFile}
                className={cs.mixImg}
                alt={null}
              />
            </span>
          </div>
          <div
            className={
              document.documentFile && document.documentFile?.endsWith('pdf')
                ? ''
                : cs.hide
            }
          >
            <Link
              href={document.documentFile ? document.documentFile : ''}
              target="_blank"
              area=""
            >
              <Icon name="attach" size={16} />
              {document.documentFileName || 'pdf'}
            </Link>
          </div>
        </div>
      }
      right={
        <div className={inProduct ? cs.inProduct : ''}>
          <div>
            <span>
              <div className={cs.trash}>
                <Button
                  className={cs.button}
                  size="medium"
                  onClick={() => onSelected(index)}
                  disabled={disabled}
                >
                  {' '}
                  {t('buttons.edit')}{' '}
                </Button>
                <Button
                  size="medium"
                  onClick={() => onDelete(index)}
                  disabled={disabled}
                >
                  {' '}
                  {t('buttons.delete')}{' '}
                </Button>
              </div>
            </span>
          </div>
        </div>
      }
    />
  );

  return <>{!isEmpty(documents) && documents.map(renderRow)}</>;
}

export function ProductSafetyRow({
  productSafety,
  onSelected,
  onDelete,
  inProduct,
  disabled,
}: {
  productSafety: any,
  onSelected: () => void,
  onDelete: () => void,
  inProduct?: ?boolean,
  disabled?: boolean,
}): React.Node {
  const pgs = getOptions(useReactiveVar(gpsrConfigsVar), 'pictograms');
  const sts = getOptions(useReactiveVar(gpsrConfigsVar), 'statements');

  if (isEmpty(productSafety)) return null;

  const pictograms = productSafety?.pictograms || [];
  const statements = productSafety?.statements || [];
  const component = productSafety?.component || '';

  return (
    <TwoColumnsLayout
      key="ProductSafetyRow"
      left={
        <div className={inProduct ? cs.inProduct : ''}>
          <div>
            <div className={cs.imgDiv}>
              {pictograms.map((pictogram) => {
                const url = pgs.find((v) => v.value === pictogram)?.url;
                return <img src={url} alt={pictogram} className={cs.mixImg} />;
              })}
            </div>
          </div>
          <div>
            <span>
              {sts
                .filter((item) => statements.includes(item.value))
                .map((item, index) => (
                  <div style={{ marginBottom: 10 }}>
                    {index + 1}.{item.label}
                  </div>
                ))}
            </span>
          </div>
          <div className={cs.bottom}>
            <span>{component}</span>
          </div>
        </div>
      }
      right={
        <div className={inProduct ? cs.inProduct : ''}>
          <div>
            <span>
              <div className={cs.trash}>
                <Button
                  className={cs.button}
                  size="medium"
                  onClick={() => onSelected()}
                  disabled={disabled}
                >
                  {' '}
                  {t('buttons.edit')}{' '}
                </Button>
                <Button
                  size="medium"
                  onClick={() => onDelete()}
                  disabled={disabled}
                >
                  {' '}
                  {t('buttons.delete')}{' '}
                </Button>
              </div>
            </span>
          </div>
        </div>
      }
    />
  );
}
